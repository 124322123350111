import React from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">{t("termsAndConditionsTitle")}</h1>
      <p className="mt-4">{t("termsAndConditionsIntroText")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection1Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection1Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection2Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection2Text")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("termsAndConditionsSection2Item1")}</li>
        <li>{t("termsAndConditionsSection2Item2")}</li>
        <li>{t("termsAndConditionsSection2Item3")}</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection3Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection3Text")}</p>
      <ul className="list-disc list-inside mt-2">
        <li>{t("termsAndConditionsSection3Item1")}</li>
        <li>{t("termsAndConditionsSection3Item2")}</li>
        <li>{t("termsAndConditionsSection3Item3")}</li>
      </ul>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection4Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection4Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection5Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection5Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection6Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection6Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection7Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection7Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection8Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection8Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection9Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection9Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection10Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection10Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection11Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection11Text")}</p>

      <h2 className="text-xl font-semibold mt-6">
        {t("termsAndConditionsSection12Title")}
      </h2>
      <p className="mt-2">{t("termsAndConditionsSection12Text")}</p>
    </div>
  );
};

export default TermsAndConditions;
