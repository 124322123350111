import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface FooterProps {
  closeNavbarAndScrollToTop: () => void;
}

const Footer: React.FC<FooterProps> = ({ closeNavbarAndScrollToTop }) => {
  const { t } = useTranslation();
  return (
    <footer className="bg-white shadow m-0 dark:bg-gray-800">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-xl text-gray-500 dark:text-gray-400">
          © 2024{" "}
          <Link to="https://rbelful.com/" className="hover:underline">
            Rbelful™
          </Link>
          . {t("allRigthsReserved")}
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-xl font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <Link
              to="/privacy-policy"
              className="hover:underline me-4 md:me-6"
              onClick={closeNavbarAndScrollToTop}
            >
              {t("privacyPolicy")}
            </Link>
          </li>
          <li>
            <Link
              to="/terms-and-conditions"
              className="hover:underline me-4 md:me-6"
              onClick={closeNavbarAndScrollToTop}
            >
              {t("termsAndConditionsTitle")}
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
