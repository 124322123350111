import React from "react";
import { useTranslation } from "react-i18next";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold">{t("homeHeaderTitle")}</h1>
      <p className="my-4 text-xl">{t("homeHeaderBody1")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody2")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody3")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody4")}</p>
      <p className="my-4 text-xl">{t("homeHeaderBody5")}</p>

      <h1 className="text-3xl font-bold mb-1">Rbelful</h1>
      <a href="https://play.google.com/store/apps/details?id=com.rbelful">
        <img
          src={require(`../images/get-it-on-google-play/${i18n.resolvedLanguage}.png`)}
          alt="Login Screen"
          className="mx-auto"
          style={{ height: "auto" }}
        />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.rbelful">
        <img
          src={require(`../images/icon-400px.png`)}
          alt="Login Screen"
          className="border-2 rounded border-green-600 mt-4 w-full max-w-xs mx-auto"
          style={{ height: "auto" }}
        />
      </a>
    </div>
  );
};

export default Home;
