import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Contact from "./components/contact";
import OurMission from "./components/our-mission";
/* import Tutorial from "./components/tutorial";
import Donation from "./components/donation"; */
import PrivacyPolicy from "./components/privacy-policy";
import Home from "./components/home";
import Footer from "./components/footer";

import TermsAndConditions from "./components/terms-condition";
import "./i18n";

const App: React.FC = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false);

  const closeNavbarAndScrollToTop = () => {
    setIsNavbarOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="App flex flex-col min-h-screen">
      <Navbar isOpen={isNavbarOpen} setIsOpen={setIsNavbarOpen} />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="our-mission" element={<OurMission />} />
          {/* <Route path="tutorial" element={<Tutorial />} /> */}
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="donation" element={<Donation />} /> */}
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer closeNavbarAndScrollToTop={closeNavbarAndScrollToTop} />
    </div>
  );
};

export default App;
